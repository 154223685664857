<template>
    <div class="msp-recupera-dati-strava">
        <v-alert dismissible type="info" :value="loading">
            <v-progress-circular
                size="20"
                indeterminate
                color="primary"
            ></v-progress-circular>
            Elaborazione in corso...
        </v-alert>


                <v-alert dismissible :type="message.type" :value="!!message.text">
                    {{message.text}}
                    </v-alert>
                    <template v-if="connesso">
                        <template v-if="!loading">


                            <div class='msp-recupera-dati-strava__edit'>
                                <v-dialog
                                    ref="dialogData"
                                    v-model="editing"
                                    :return-value.sync="data"
                                    persistent
                                    width="290px"
                                >
                                    <v-date-picker
                                        v-model="data"
                                        scrollable
                                        :first-day-of-week="1"
                                        locale="it-it"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="editing = false"
                                        >
                                            Annulla
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            @click="$refs.dialogData.save(data); recuperaDatiStrava()"
                                        >
                                            Recupera Dati
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                                <v-btn 
                                    @click="editing = true"
                                >
                                    Recupera dati Strava
                                </v-btn>
                            </div>

</template>

</template>
<p  v-else>
Collegati a Strava per recuperare i dati delle tue attività.
<v-btn
@click.stop="dialog = true"
icon
>
<v-icon>mdi-help-circle</v-icon></v-btn>
</p>
<v-dialog
v-model="dialog"
>

<v-card>
<v-card-title class="text-h5 grey lighten-2">
Recupero dati Strava
</v-card-title>

<v-card-text>
<p>Dopo esserti collegato al tuo account Strava, potrai recuperare i dati degli ultimi 3 mesi di attività.</p>
<p>In questo modo potrai analizzare da subito le tue performance all'interno dei nostri grafici.</p>
</v-card-text>

</v-card>
</v-dialog>
</div>

</template>

<script>
import api from "../../../js/msp/api.js";
  import allenamentoCalcoli from "@src/js/vue/mixins/allenamentoCalcoli.js";
import CONFIG from "../../../js/msp/config.js";
    const component = {
        mixins: [
      allenamentoCalcoli,
        ],
        props: [
            "connesso",
            "atletaId"
        ],
        computed: {
        },
        data() {
            return {
                dialog: false,
                editing: false,
                message: {
                    'type': null,
                    'text': null,
                },
                loading: false,
                data: null,
                tooltips: {
                    "incorso": false,
                },
            }
        },
        mounted: function () {
            this.init();
        },
        watch: {
            atletaId: function (nv,ov) {
                    if (nv === ov) return;
            }

        },
        methods: {
            setSuccess: function (text) {
                this.message = {
                    type: 'success',
                    text: text,
                };
            },
            startLoading: function () {
                this.loading = true;
                this.editing = false;
                this.resetMessage();
            },
            resetMessage: function () {
                this.message = {
                    type: null,
                    text: null,
                };
            },
            stopLoading: function () {
                this.loading = false;
                this.editing = false;
                this.data = null;
            },
            edit: function () {
            },
            init: function () {
            },
            showTooltip: function (tooltip) {
                return this.tooltips[tooltip];
            },
            toggleTooltip: function (tooltip) {
                this.tooltips[tooltip] = !this.tooltips[tooltip];
            },
            recuperaDatiStrava: function () {
                if (!this.atletaId) {
                    return;
                }
                const atletaId = this.atletaId;
                const data = this.data;
                return new Promise((resolve, reject)=>{
                    this.startLoading();
                    api.get(`recupera_dati_strava.php?data=${data}&atleta_id=${atletaId}`, CONFIG.api_base_url+"/api/v2")
                        .then((result)=>{
                            this.stopLoading();
                            if (result.success) {
                                this.setSuccess("Allenamento recuperato.");
                            } else {
                                this.setSuccess("Nessun allenamento da recuperare.");
                            }
                            resolve(result);
                        })
                        .catch((error) => {
                            this.stopLoading();
                            this.setSuccess("Ops... Qualcosa non va. Segnalalo a CoachPeaking.");
                            reject(error);
                        });
                });
            },
        }
    }
    export default component;
</script>

<style lang="scss">
    .msp-recupera-dati-strava {
      --bg-col: var(--col-grigio-chiarissimo);
        .tooltip {
            position: relative;
        }
        
        .tooltip-dettagli {
            position: absolute;
            top: 0;
             left: 50%;
             transform: translate(-50%,0);
             width: 300px;
             padding: 10px;
             background: var(--bg-col);
             border-radius: 5px;
             box-shadow: 0 0 5px rgba(0,0,0,0.5);
        }
        .recupera-alert {
            padding: 2px 5px;
            font-size: 0.8em;
        padding: 7px 10px;
          background: var(--bg-col);
            border-radius: 3px;

        }
        .recupera-alert-success {
              --bg-col: var(--col-msp-chiarissimo);
        }

        .recupera-alert-danger {
              --bg-col: var(--col-tint-danger);
        }
    }
</style>
